import React, {Component} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Alert
} from "reactstrap";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {login} from "../../actions/authActions";
import {clearErrors} from "../../actions/errorActions";


class LoginModal extends Component {

    state = {
        modal: !localStorage.getItem('user'),
        email: '',
        password: '',
        msg: null
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
    }

    componentDidUpdate(prevProps) {
        const {error, isAuthenticated} = this.props;
        if (error != prevProps.error) {
            // Check for register error
            if (error.id === 'LOGIN_FAIL') {
                this.setState({msg: error?.msg?.msg})
            } else {
                this.setState({msg: null})
            }
        }

        // If authenticated, close modal
        if (this.state.modal) {
            if (isAuthenticated) {
                this.toggle();
            }
        }
    }

    toggle = () => {
        // Clear errors
        this.props.clearErrors(),
            this.setState({
                modal: !this.state.modal
            });
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = e => {
        e.preventDefault();

        let {email, password} = this.state;

        if(email.includes('@')){
            email.toLowerCase();
        }else{
            email = email.toUpperCase();
        }

        const user = {
            email,
            password
        };

        // Attempt to Login
        this.props.login(user);
    };


    render() {
        return (
            <div>
                <NavLink onClick={this.toggle} href="#">
                    Se connecter
                </NavLink>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>
                        Se connecter
                    </ModalHeader>
                    <ModalBody>
                        {this.state.msg ? (
                            <Alert color='danger'> {this.state.msg}</Alert>
                        ) : null}
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>

                                <Label for="email">
                                    Email, Nom ou Prenom *
                                </Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Email, nom ou prenom"
                                    class="mb-3"
                                    required="true"
                                    onChange={this.onChange}
                                />
                                <Label for="password">
                                    Mot de passe *
                                </Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    class="mb-3"
                                    onChange={this.onChange}
                                />
                                <Button
                                    color="dark"
                                    style={{marginTop: '2rem'}}
                                    block
                                >
                                    Se connecter
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
});

export default connect(mapStateToProps, {login, clearErrors})(LoginModal);