export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_TASKS_OF_TODAY = 'GET_ALL_TASKS_OF_TODAY';
export const GET_ALL_TASKS_OF_TODAY_OF_USER = 'GET_ALL_TASKS_OF_TODAY_OF_USER';
export const GET_ALL_TASKS_OF_CUSTOMER = 'GET_ALL_TASKS_OF_CUSTOMER';
export const GET_ALL_TASKS_OF_USER = 'GET_ALL_TASKS_OF_USER';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const ADD_TASK = 'ADD_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK_DETAILS = 'DELETE_TASK_DETAILS';
export const DELETE_TASK = 'DELETE_TASK';
export const TASKS_LOADING = 'TASKS_LOADING';

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_DETAILS = 'DELETE_CUSTOMER_DETAILS';
export const CUSTOMERS_LOADING = 'CUSTOMERS_LOADING';

export const GET_ALL_COMMENTS_OF_CUSTOMER = 'GET_ALL_COMMENTS_OF_CUSTOMER';
export const GET_COMMENTS_DETAILS = 'GET_COMMENTS_DETAILS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_DETAILS = 'DELETE_COMMENT_DETAILS';
export const COMMENTS_LOADING = 'COMMENTS_LOADING';

export const GET_ALL_DOCUMENTS_OF_CUSTOMER = 'GET_ALL_DOCUMENTS_OF_CUSTOMER';
export const GET_DOCUMENTS_DETAILS = 'GET_DOCUMENTS_DETAILS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_DETAILS = 'DELETE_DOCUMENT_DETAILS';
export const DOCUMENTS_LOADING = 'DOCUMENTS_LOADING';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER_DETAILS = 'DELETE_USER_DETAILS';
export const DELETE_USER = 'DELETE_USER';
export const USERS_LOADING = 'USERS_LOADING';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
