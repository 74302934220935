import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo,faTrash, faUserPlus, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import {deleteCustomer, getAllCustomers} from "../actions/customerActions";
import Swal from "sweetalert2";
import  { Link, Redirect } from 'react-router-dom';
import {isMobile} from 'react-device-detect'
if (isMobile) { require('./customer.scss'); }

moment.locale('fr');


const {SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const handleClick = (dispatch, id) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer ce client ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteCustomer(id))
                Swal.fire({
                    title: "Client supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                        if(result.isConfirmed){
                            dispatch(getAllCustomers());
                        }
                    });
            } else {
                Swal.fire("Le client est sauvegardé", "","info");
            }
        });
}



const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}]

const mapStateToProps = (state) => {
    return {
        getAllCustomers: state.customers.getAllCustomers,
        isAuthenticated: state.auth.isAuthenticated,
        errorCustomersList: state.customers.errorCustomersList,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.customers.loading
    }
}

const CustomerTableComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!props.isAuthenticated){
        <Link to="/"/>
    }

    !localStorage.getItem('optionsCustomer') ? localStorage.setItem('optionsCustomer', '1') : localStorage.getItem('optionsCustomer');
    const test = parseInt(localStorage.getItem('optionsCustomer'));
    const optionsCustomer = {
        page: test,
        onPageChange: (page) => {
            {localStorage.setItem('optionsCustomer', page)}
        }
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "8%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'lastname',
            text: 'Nom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'firstname',
            text: 'Prenom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"mailto:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'phone',
            text: 'Téléphone',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"tel:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'portable',
            text: 'Portable',
            sort: true,
            hidden: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"tel:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'register_date',
            text: 'Enregistré le',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'membership',
            text: 'Client/Prospect',
            sort: true,
            style: {
                width: "10%",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "Client"
                    )
                }else{
                    return (
                        "Prospect"
                    )
                }
            },
            csvFormatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "Client"
                    )
                }else{
                    return (
                        "Prospect"
                    )
                }
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'user_id',
            text: 'Id du responsable',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'user_firstname',
            text: 'Prenom du responsable',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                            row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'user_lastname',
            text: 'Nom de famille du responsable',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'street',
            text: 'Rue',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'zip',
            text: 'Code postal',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'city',
            text: 'Ville',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'state',
            text: 'Pays',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'street_delivery',
            text: 'Rue de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'zip_delivery',
            text: 'Code postal de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'city_delivery',
            text: 'Ville de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'state_delivery',
            text: 'Pays de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'siret',
            text: 'Siret',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: "link",
            text: "Action",
            csvExport: false,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "16%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={"detailCustomer/" + row._id}>
                            <Button color="info" className="mr-2">
                                <FontAwesomeIcon icon={faInfo}/>
                            </Button>
                        </Link>
                            <Link to={"editCustomer/" + row._id}>
                                <Button color="warning" className="mr-2">
                                    <FontAwesomeIcon icon={faUserEdit}/>
                                </Button>
                            </Link>
                        {user?.role === 'admin' &&
                        <Button color="danger" className="mr-2" onClick={() => handleClick(props.dispatch,row._id)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                        }
                    </div>
                )
            }
        }
    ];

    return (
    <Container>
        <div>
            <h2>
                Liste des client ({props.getAllCustomers.length})
            </h2>
        </div>
        {props.getAllCustomers ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={props.getAllCustomers}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextCustomers')
                } }
                exportCSV={ {
                    fileName: 'Clients.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                                <Col>
                                    <Link to={"createCustomer"}>
                                        <Button color="primary" className="mr-2">
                                            <FontAwesomeIcon icon={faUserPlus}/> Ajouter un client
                                        </Button>
                                    </Link>
                                    {user?.role === 'admin' &&
                                    <ExportCSVButton {...props.csvProps} className="mr-2">
                                        <Button color="info">
                                            Export au format CSV
                                        </Button>
                                    </ExportCSVButton>
                                    }
                                </Col>
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                    {localStorage.setItem('searchTextCustomers', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable id="table-to-xls"
                                        striped
                                        hover
                                        printable
                                        wrapperClasses="table-responsive"
                                        bodyStyle={{overflow: 'overlay'}}
                                        {...props.baseProps} pagination={paginationFactory(optionsCustomer)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorCustomersList ? <h4>{props.errorCustomersList}</h4> :
                        <Spinner color="dark"/>
                    }
                    {
                        props.loading ? <Spinner color="dark"/> : null
                    }
                </div>
            )}
        <div className="text-center">
            {
                props.loading ? <Spinner color="dark"/> : null
            }
        </div>
    </Container>

)
};


export default connect(mapStateToProps, null)(CustomerTableComponent);