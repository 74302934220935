import React, {Component} from 'react';
import TaskTableComponent from "../../components/TaskTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllTasks, deleteDataTask, getAllTasksOfToday, getAllTasksOfUser, getAllTasksOfTodayOfUser} from '../../actions/taskActions';
import {getAllUsers} from "../../actions/userActions";
import {getAllCustomers} from "../../actions/customerActions";


 class TaskContainer extends Component {

     state = {
         msg: null,
     }
     componentDidMount() {
         const user = JSON.parse(localStorage.getItem('user'));
         this.props.getAllTasks();
         this.props.getAllTasksOfToday();
         this.props.isAuthenticated || user ? this.props.getAllTasksOfTodayOfUser(user.id) : null;
         this.props.isAuthenticated || user ? this.props.getAllTasksOfUser(user.id): null;
         this.props.getAllCustomers();
         this.props.getAllUsers();
         this.props.deleteDataTask();
     }

     componentDidUpdate(prevProps) {
        if ( this.props.isAuthenticated != prevProps.isAuthenticated) {
            const user = JSON.parse(localStorage.getItem('user'));
            this.props.isAuthenticated || user ? this.props.getAllTasksOfTodayOfUser(user.id) : null;
            this.props.isAuthenticated || user ? this.props.getAllTasksOfUser(user.id) : null;
        }
     }
     render() {
         return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <TaskTableComponent/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllTasks: state.tasks.getAllTasks,
    getAllTasksOfToday: state.tasks.getAllTasksOfToday,
    getAllTasksOfTodayUser: state.tasks.getAllTasksOfTodayUser,
    getAllTasksOfUser: state.tasks.getAllTasksOfUser,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllTasks,getAllCustomers, getAllTasksOfToday, getAllTasksOfTodayOfUser, getAllTasksOfUser, getAllUsers, deleteDataTask})(TaskContainer)