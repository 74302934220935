import React , { useState } from 'react';
import download from 'downloadjs';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTasks, faTrash, faFileDownload, faEye} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Link} from 'react-router-dom';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import Swal from "sweetalert2";
import {deleteDocument, getAllDocumentsOfCustomer} from "../actions/documentActions";


moment.locale('fr');


const {SearchBar } = Search;

    const handleClick = (dispatch, id, props) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer ce document ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteDocument(id))
                Swal.fire({
                    title: "Document supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                    if(result.isConfirmed){
                        dispatch(getAllDocumentsOfCustomer(props.getCustomerDetail._id))
                    }
                });
            } else {
                Swal.fire("La document est sauvegardé", "","info");
            }
        });
}



const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}]

const mapStateToProps = (state) => {
    return {
        getCustomerDetail: state.customers.getCustomerDetail,
        getAllTasksOfCustomer: state.tasks.getAllTasksOfCustomer,
        getAllDocumentsOfCustomer: state.documents.getAllDocumentsOfCustomer,
        errorCustomerDetail: state.customers.errorCustomerDetail,
        isAuthenticated: state.auth.isAuthenticated,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.documents.loading
    }
}

const DocumentCustomerTableComponent = (props) => {

    const [errorMsg, setErrorMsg] = useState('');

    const downloadFile = async (id, path, mimetype) => {
        console.log(id, path, mimetype);
        try {
            const result = await axios.get(`/api/documents/download/${id}`, {
                responseType: 'blob'
            });
            const split = path.split('/');
            const filename = split[split.length - 1];
            setErrorMsg('');
            return download(result.data, filename, mimetype);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setErrorMsg('Erreur lors du téléchargement du fichier. Réessayez plus tard');
            }
        }
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'name',
            text: 'Nom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'date',
            text: 'Enregistré le',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'user_id',
            text: 'Ajouté par: ',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'user_firstname',
            text: 'Ajouté par: ',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if(row.user_firstname && !row.user_lastname){
                    return row.user_firstname
                } else if(!row.user_firstname && row.user_lastname){
                    return row.user_lastname
                } else if(row.user_firstname && row.user_lastname){
                    return row.user_firstname + ' ' + row.user_lastname
                }
            }
        },
        {
            dataField: 'customer_id',
            text: 'Relié au client : ',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'customer_firstname',
            text: 'Relié au client : ',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if(row.customer_firstname && !row.customer_lastname){
                    return row.customer_firstname
                } else if(!row.customer_firstname && row.customer_lastname){
                    return row.customer_lastname
                } else if(row.customer_firstname && row.customer_lastname){
                    return row.customer_firstname + ' ' + row.customer_lastname
                }
            }
        },
        {
            dataField: "link",
            text: "Action",
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "18%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <a href={row.fileLink} target="_blank" >
                            <Button color="success" className="mr-2">
                                <FontAwesomeIcon icon={faEye}/>
                            </Button>
                        </a>
                        {
                            /*
                            <Button color="info" className="mr-2" onClick={() => downloadFile(row._id, row.fileLink, row.file_mimetype)}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                            </Button>
                             */
                        }

                        <Button color="danger" className="mr-2" onClick={() => handleClick(props.dispatch,row._id, props)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                )
            }
        }
    ];

    const _id = props.getCustomerDetail._id;
    return (
        <Container>
            <div>
                <h2>
                    Liste des documents sur le client ({props.getAllDocumentsOfCustomer.length})
                </h2>
            </div>
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        {props.getAllDocumentsOfCustomer ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={props.getAllDocumentsOfCustomer}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextDocumentCustomers')
                } }
            >
                {(props) => (

                    <div>
                        <Row>
                            <Col>
                                <Link to={"/createDocument/" + _id}>
                                    <Button color="primary" className="mr-2">
                                        <FontAwesomeIcon icon={faTasks}/> Ajouter un document
                                    </Button>
                                </Link>
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                    {localStorage.setItem('searchTextDocumentCustomers', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable striped
                                        hover
                                        {...props.baseProps} pagination={paginationFactory()}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorDocumentsList ? <h4>{props.errorDocumentsList}</h4> :
                            <Spinner color="dark"/>
                    }
                </div>
            )}
            <div className="text-center">
                {
                    props.loading ? <Spinner color="dark"/> : null
                }
            </div>
        </Container>

)
};


export default connect(mapStateToProps, null)(DocumentCustomerTableComponent);