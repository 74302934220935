import React, {Component} from 'react';
import {Container} from "reactstrap";
import BackComponent from "../../components/BackComponent";
import { connect } from "react-redux";
import {getTaskDetails} from "../../actions/taskActions";
import DetailTaskComponent from "../../components/DetailTaskComponent";


const mapStateToProps = (state) => {
    return{
        getTaskDetail: state.tasks.getTaskDetail,
        errorTaskDetail: state.tasks.errorTaskDetail,
    }
}

 class DetailTaskContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getTaskDetails(this.props.match.params.id));
     }

     render() {
        return (
            <Container>
                <BackComponent />
                <h1>Détails de la tâche {this.props.getTaskDetail.type} </h1>
                <DetailTaskComponent />
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(DetailTaskContainer)