import {combineReducers} from "redux";
import taskReducer from './taskReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import {reducer as formReducer} from 'redux-form'
import customerReducer from "./customerReducer";
import commentReducer from "./commentReducer";
import documentReducer from "./documentReducer";

export default combineReducers({
    tasks: taskReducer,
    error: errorReducer,
    auth: authReducer,
    users: userReducer,
    customers: customerReducer,
    comments: commentReducer,
    documents: documentReducer,
    form: formReducer
});