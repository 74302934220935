import React, {Component} from 'react';
import {Container} from "@material-ui/core";
import BackComponent from "../../components/BackComponent";
import TaskFormComponent from "../../components/TaskFormComponent";
import {connect} from 'react-redux';
import {addtask} from "../../actions/taskActions";
import {getAllUsers} from "../../actions/userActions";
import {getAllCustomers} from "../../actions/customerActions";
import moment from "moment";
import Swal from 'sweetalert2';
import {Redirect, Link} from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getResponDataTask: state.tasks.getResponDataTask,
        errorResponDataTask: state.tasks.errorResponDataTask,

    };
};

 class CreateTaskContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getAllUsers());
         this.props.dispatch(getAllCustomers());
     }

    handleSubmit(data){
        data.date = new Date(data.date).toISOString();

        if(data.user_id){
            data.user_id = data.user_id?.value;
        }

     {this.props.getAllUsers.map((user) => {
         if(user._id == data.user_id){
             data.user_firstname = user.firstname ? user.firstname : '';
             data.user_lastname = user.lastname ? user.lastname : '';
         }
            }
        )}

        if(data.customer_id){
            data.customer_id = data.customer_id?.value;
        }
        {this.props.getAllCustomers.map((customer) => {
            if(customer._id == data.customer_id){
                data.customer_firstname = customer.firstname ? customer.firstname : '';
                data.customer_lastname = customer.lastname ? customer.lastname : '';
            }
            }
        )}
        this.props.dispatch(addtask(data))
    }

     render() {
         if(this.props.getResponDataTask || this.props.errorResponDataTask) {
             if (this.props.errorResponDataTask) {
                 Swal.fire(
                     "Echec ! ",
                     this.props.errorResponDataTask,
                     "error"
                 );
             } else {

                 Swal.fire({
                     title: "Actions créee !",
                     icon :"success",
                     showCloseButton: true,
                 }).then((result) => {
                     if (result.isConfirmed) {
                         window.location = "detailCustomer/" + this.props.getResponDataTask.customer_id

                     }
                 });
             }
         }
         return (
            <Container>
                <BackComponent />
                <h1>Crée une tache</h1>
                <TaskFormComponent onSubmit={(data) => this.handleSubmit(data)}/>
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(CreateTaskContainer)