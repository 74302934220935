import React, {Component} from 'react';
import {Container} from "reactstrap";
import BackComponent from '../../components/BackComponent';
import {connect} from 'react-redux';
import UserFormComponent from "../../components/UserFormComponent.js";
import {getUserDetails, updateUser} from "../../actions/userActions";
import Swal from "sweetalert2";
import  { Link, Redirect } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getUserDetail: state.users.getUserDetail,
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getResponDataUser: state.users.getResponDataUser,
        errorResponDataUser: state.users.errorResponDataUser,
        isAuthenticated: state.auth.isAuthenticated
    };
};

 class EditUserContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getUserDetails(this.props.match.params.id));
     }

     handleSubmit(data){
         {this.props.getAllUsers.map((user) => {
                 if(user._id == data.user_id){
                     data.user_firstname = user.firstname
                     data.user_lastname = user.lastname
                 }
             }
         )}
         data.firstname ? data.firstname = data.firstname.toUpperCase() : null;
         data.lastname ? data.lastname = data.lastname.toUpperCase(): null;
         data.email ? data.email = data.email.toLowerCase(): null;

         this.props.dispatch(updateUser(data, this.props.match.params.id))
     }
    render() {
        if(this.props.getResponDataUser || this.props.errorResponDataUser) {
            if (this.props.errorResponDataUser) {
                Swal.fire(
                    "Echec ! ",
                    this.props.errorResponDataUser,
                    "error"
                );
            } else {

                Swal.fire({
                    title: "Utilisateur modifié !",
                    icon :"success"
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.history.back();
                    }
                })
            }
        }

        return (
            <Container>
                <div>
                    {this.props.isAuthenticated && this.props?.getUserConnected?.role === 'admin' &&
                    <div>
                        <BackComponent />
                        <h1>Modifier l'utilisateur {this.props.getUserDetail.firstname} {this.props.getUserDetail.lastname}</h1>
                        <UserFormComponent onSubmit={(data) => this.handleSubmit(data)} />
                    </div>
                    }
                    {this.props?.getUserConnected?.role !== 'admin' &&
                    <div>
                        <h2>Vous ne possedez pas les accés pour accéder à cette page</h2>
                        <Link to='/'/>
                    </div>
                    }
                    {!this.props.isAuthenticated &&
                    <div>
                        <h2>Veuillez vous connecter pour accéder au CRM</h2>
                        <Link to='/'/>
                    </div>
                    }

                </div>
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(EditUserContainer)