import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faTasks, faEdit, faTrash, faUserPlus, faPlus} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import {
    deleteTask,
    getAllTasks,
    getAllTasksOfCustomer,
    getAllTasksOfToday,
    getAllTasksOfTodayOfUser, getAllTasksOfUser
} from "../actions/taskActions";
import Swal from "sweetalert2";
import  { Link,Redirect } from 'react-router-dom';
import {getCustomerDetails} from "../actions/customerActions";


moment.locale('fr');


const {SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const handleClick = (dispatch, id) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer cette tâche ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteTask(id))
                Swal.fire({
                    title: "Tâche supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                    if(result.isConfirmed){
                        const user = JSON.parse(localStorage.getItem('user'));
                        dispatch(getAllTasks());
                        dispatch(getAllTasksOfToday());
                        dispatch(getAllTasksOfTodayOfUser(user.id));
                        dispatch(getAllTasksOfUser(user.id))
                    }
                });
            } else {
                Swal.fire("La tâche est sauvegarder", "","info");
            }
        });
}


const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}]

const mapStateToProps = (state) => {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        getAllTasks: state.tasks.getAllTasks,
        getAllTasksOfToday: state.tasks.getAllTasksOfToday,
        getAllTasksOfTodayOfUser: state.tasks.getAllTasksOfTodayOfUser,
        getAllTasksOfUser: state.tasks.getAllTasksOfUser,
        isAuthenticated: state.auth.isAuthenticated,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.tasks.loading
    }
}

const TaskTableComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const handleClickCreate = (row) => {
        {props.dispatch(getCustomerDetails(row.customer_id))}
        return <Link to={"createTask"}/>
    }

    if(!props.isAuthenticated){
        return <Link to="/"/>
    }

   !localStorage.getItem('paginationTaskUserDay') ? localStorage.setItem('paginationTaskUserDay', '1') : localStorage.getItem('paginationTaskUserDay');
    const test = parseInt(localStorage.getItem('paginationTaskUserDay'));
    const optionsTaskUserDay = {
        page: test,
        onPageChange: (page) => {
            {localStorage.setItem('paginationTaskUserDay', page)}
        }
    };
    !localStorage.getItem('paginationTaskUser')? localStorage.setItem('paginationTaskUser', '1') : localStorage.getItem('paginationTaskUser');
    const test1 = parseInt(localStorage.getItem('paginationTaskUser'));
    const optionsTaskUser = {
        page: test1,
        onPageChange: (page) => {
            {localStorage.setItem('paginationTaskUser', page)}
        }
    };

    !localStorage.getItem('paginationAllTask')? localStorage.setItem('paginationAllTask', '1') : localStorage.getItem('paginationAllTask');
    const test2 = parseInt(localStorage.getItem('paginationAllTask'));
    const optionsAllTask = {
        page: test2,
        onPageChange: (page) => {
            {localStorage.setItem('paginationAllTask', page)}
        }
    };
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'customer_id',
            text: 'Client Id',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'customer_firstname',
            text: 'Client',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    <Link to={"detailCustomer/" + row.customer_id}>
                            {
                                row.customer_firstname ? row.customer_firstname + ' ' : null
                            }

                            {
                                row.customer_lastname ? row.customer_lastname + ' ' : null
                            }
                    </Link>

                )
            },
            filterValue: (rowContent, row) => {
                if(row.customer_firstname && !row.customer_lastname){
                    return row.customer_firstname
                } else if(!row.customer_firstname && row.customer_lastname){
                    return row.customer_lastname
                } else if(row.customer_firstname && row.customer_lastname){
                    return row.customer_firstname + ' ' + row.customer_lastname
                }
            },
        },
        {
            dataField: 'customer_lastname',
            text: 'Client',
            sort: true,
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    <Link to={"detailCustomer/" + row.customer_id}>
                            {
                                row.customer_firstname ? row.customer_firstname + ' ' : null
                            }

                            {
                                row.customer_lastname ? row.customer_lastname + ' ' : null
                            }
                    </Link>
                )
            },
            filterValue: (rowContent, row) => {
                if(row.customer_firstname && !row.customer_lastname){
                    return row.customer_firstname
                } else if(!row.customer_firstname && row.customer_lastname){
                    return row.customer_lastname
                } else if(row.customer_firstname && row.customer_lastname){
                    return row.customer_firstname + ' ' + row.customer_lastname
                }
            },
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'date',
            text: 'A faire le',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            style: function(rowContent, row) {
                if(rowContent) {
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    })
                }else{
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: 'lime'
                    })
                }

            },
            formatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "A faire"
                    )
                }else{
                    return (
                        "Fait"
                    )
                }
            },
            csvFormatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "A faire"
                    )
                }else{
                    return (
                        "Fait"
                    )
                }
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'user_id',
            text: 'Commercial ID',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'user_firstname',
            text: 'Commercial',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                        row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'user_lastname',
            text: 'Nom du Commercial',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (

                        row.user_firstname + ' ' + row.user_lastname

                )
            }
        },
        {
            dataField: "link",
            text: "Action",
            csvExport: false,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "18%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={"createTask"}>
                            <Button color="success" className="mr-1" onClick={() => handleClickCreate(row)}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </Link>
                        <Link to={"detailTask/" + row._id}>
                            <Button color="info" className="mr-1">
                                <FontAwesomeIcon icon={faInfo}/>
                            </Button>
                        </Link>
                        <Link to={"editTask/" + row._id}>
                            <Button color="warning" className="mr-1">
                                <FontAwesomeIcon icon={faEdit}/>
                            </Button>
                        </Link>
                        <Button color="danger" className="mr-1" onClick={() => handleClick(props.dispatch,row._id)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                )
            }
        }
    ];
    return (
    <Container>
        <div>
            <h2>
                Liste des actions du jour ({props.getAllTasksOfTodayOfUser.length})
            </h2>
        </div>
        {props.getAllTasksOfTodayOfUser ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={props.getAllTasksOfTodayOfUser}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextTasksTodayUser')
                } }
                exportCSV={ {
                    fileName: 'Liste_des_taches_du_jour_de'+props.getAllTasksOfUser.user_firstname + '_'+props.getAllTasksOfUser.user_lastname+'.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                            <Col>
                                <Link to={"createTask"}>
                                    <Button color="primary" className="mr-2">
                                        <FontAwesomeIcon icon={faTasks}/> Crée une tache
                                    </Button>
                                </Link>
                                    <ExportCSVButton {...props.csvProps} className="mr-2">
                                        <Button color="info">
                                            Export au format CSV
                                        </Button>
                                    </ExportCSVButton>
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Search.."/>
                                    {localStorage.setItem('searchTextTasksTodayUser', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable striped
                                        hover
                                        {...props.baseProps} pagination={paginationFactory(optionsTaskUserDay)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorTasksList ? <h4>{props.errorTasksList}</h4> :
                        <Spinner color="dark"/>
                    }
                </div>
            )}

        <div>
            <h2>
                Liste de toutes vos actions ({props.getAllTasksOfUser.length})
            </h2>
        </div>
        {props.getAllTasksOfUser ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={props.getAllTasksOfUser}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextTasksUser')
                } }
                exportCSV={ {
                    fileName: 'Liste des tâches de '+props.getAllTasksOfUser.user_firstname + '_'+props.getAllTasksOfUser.user_lastname+'.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                                <Col>
                                    <ExportCSVButton {...props.csvProps} className="mr-2">
                                        <Button color="info">
                                            Export au format CSV
                                        </Button>
                                    </ExportCSVButton>
                                </Col>
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Search.."/>
                                    {localStorage.setItem('searchTextTasksUser', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable striped
                                        hover
                                        {...props.baseProps} pagination={paginationFactory(optionsTaskUser)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorTasksList ? <h4>{props.errorTasksList}</h4> :
                            <Spinner color="dark"/>
                    }
                </div>
            )}

        {user.role == 'admin' ?
            <div>
                <div>
                    <h2>
                        Liste de toutes les actions ({props.getAllTasks.length})
                    </h2>
                </div>
                {
                    props.getAllTasks ?
                        <ToolkitProvider
                            bootstrap4
                            keyField='_id'
                            data={props.getAllTasks}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            search={{
                                searchFormatted: true,
                                defaultSearch: localStorage.getItem('searchTextTasks')
                            }}
                            exportCSV={ {
                                fileName: 'Liste_des_toutes_les_taches.csv',
                                onlyExportFiltered: true,
                                exportAll: false,
                                separator: ";"
                            }}

                        >
                            {(props) => (
                                <div>
                                    <Row>
                                            <Col>
                                                <ExportCSVButton {...props.csvProps} className="mr-2">
                                                    <Button color="info">
                                                        Export au format CSV
                                                    </Button>
                                                </ExportCSVButton>
                                            </Col>
                                        <Col>
                                            <div className="float-right">
                                                <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                                {localStorage.setItem('searchTextTasks', props.searchProps.searchText)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <BootstrapTable striped
                                                    hover
                                                    {...props.baseProps} pagination={paginationFactory(optionsAllTask)}/>
                                </div>
                            )}
                        </ToolkitProvider> :
                        (
                            <div className="text-center">
                                {
                                    props.errorTasksList ? <h4>{props.errorTasksList}</h4> :
                                        <Spinner color="dark"/>
                                }
                                {
                                    props.loading ?  <Spinner color="dark"/> : null
                                }
                            </div>
                        )}
                    <div className="text-center">
                        {
                            props.loading ? <Spinner color="dark"/> : null
                        }
                    </div>
            </div> : null
        }
    </Container>

)
}


export default connect(mapStateToProps, null)(TaskTableComponent);