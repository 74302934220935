import React, {Component} from 'react';
import {Container} from "reactstrap";
import BackComponent from '../../components/BackComponent';
import {connect} from 'react-redux';
import CustomerFormComponent from "../../components/CustomerFormComponent.js";
import {getCustomerDetails, updateCustomer} from "../../actions/customerActions";
import Swal from "sweetalert2";



const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getCustomerDetail: state.customers.getCustomerDetail,
        getResponDataCustomer: state.customers.getResponDataCustomer,
        errorResponDataCustomer: state.customers.errorResponDataCustomer,

    };
};

 class EditCustomerContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getCustomerDetails(this.props.match.params.id));
     }

     handleSubmit(data){
         if(data.user_id){
             data.user_id = data.user_id?.value;
         }
         {this.props.getAllUsers.map((user) => {
                 if(user._id == data.user_id){
                     data.user_firstname = user.firstname
                     data.user_lastname = user.lastname
                 }
             }
         )}

         if(data.customer_id){
             data.customer_id = data.customer_id?.value;
         }
         {this.props.getAllCustomers.map((customer) => {
                 if(customer._id == data.customer_id){
                     data.customer_firstname = customer.firstname
                     data.customer_lastname = customer.lastname
                 }
             }
         )}
         this.props.dispatch(updateCustomer(data, this.props.match.params.id))
     }
    render() {
        if(this.props.getResponDataCustomer || this.props.errorResponDataCustomer) {
            if (this.props.errorResponDataCustomer) {
                Swal.fire(
                    "Echec ! ",
                    this.props.errorResponDataCustomer,
                    "error"
                );
            } else {
                Swal.fire({
                    title: "Client modifié !",
                    icon :"success"
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.history.back();
                    }
                })
            }
        }

        return (
            <Container>
                <BackComponent />
                <h1>Modifier le client</h1>
                <CustomerFormComponent onSubmit={(data) => this.handleSubmit(data)} />
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(EditCustomerContainer)