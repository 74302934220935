import React, {Component} from 'react';
import CustomerTableComponent from "../../components/CustomerTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllCustomers, deleteDataCustomer} from '../../actions/customerActions';
import {getAllUsers} from "../../actions/userActions";


 class CustomerContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllCustomers: PropTypes.func.isRequired,
         deleteCustomerDetails: PropTypes.func.isRequired,
         customers: PropTypes.object.isRequired,
         isAuthenticated: PropTypes.bool,
         error: PropTypes.object.isRequired,
         clearErrors: PropTypes.func.isRequired,
     }

     componentDidMount() {
         this.props.getAllCustomers();
         this.props.getAllUsers();
         this.props.deleteDataCustomer();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <CustomerTableComponent/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllCustomers: state.tasks.getAllCustomers,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllCustomers, getAllUsers, deleteDataCustomer})(CustomerContainer)