import axios from "../axios";
import {
    GET_ALL_CUSTOMERS,
    GET_CUSTOMER_DETAILS,
    ADD_CUSTOMER,
    DELETE_CUSTOMER,
    CUSTOMERS_LOADING,
    GET_TASK_DETAILS, ADD_TASK, UPDATE_TASK, UPDATE_CUSTOMER, DELETE_TASK_DETAILS, DELETE_CUSTOMER_DETAILS
} from "./types";
import {tokenConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const getAllCustomers = () => dispatch => {
    dispatch(setCustomersLoading());
    axios
        .get('/api/customers')
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_CUSTOMERS,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_CUSTOMERS,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};

export const getCustomerDetails = (id) => {
    return (dispatch) => {
        axios
            .get('/api/customers/' + id)
            .then(function (response){
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    }
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: {
                        data: false,
                        errorMessage: error?.message,
                    }
                })
            })
    }
}

export const addCustomer = (data) => {
    return (dispatch, getState) => {
        axios
            .post('/api/customers', data, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: {
                        data: response?.data,
                        errorMessage: false
                    }
                })
            }).catch(function (error) {
            dispatch({
                type: ADD_CUSTOMER,
                payload: {
                    data: false,
                    errorMessage: error?.response?.data?.msg,
                }
            });
        })
    };
}

export const updateCustomer = (customer, id) => {
    return (dispatch, getState) => {
        axios
            .put("/api/customers/"+id, customer, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    },
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: {
                        data: false,
                        errorMessage: error?.message
                    },
                });
            })
    };
}


export const deleteDataCustomer = () => {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOMER_DETAILS,
            payload: {
                data: false,
                errorMessage: false,
            }
        });

        dispatch({
            type: ADD_CUSTOMER,
            payload: {
                data: false,
                errorMessage: false,
            }
        });
    }
}

export const deleteCustomer = id => (dispatch, getState) => {
    dispatch(setCustomersLoading());
    axios
        .put(`/api/customers/delete/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_CUSTOMER_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err?.response?.data, err.response?.status)))
};

/*export const deleteCustomer = id => (dispatch, getState) => {
    axios
        .delete(`/api/customers/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_CUSTOMER_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};*/


export const setCustomersLoading = () => {
    return {
        type: CUSTOMERS_LOADING,
    };
};
