import {
    GET_ALL_CUSTOMERS,
    GET_CUSTOMER_DETAILS,
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    CUSTOMERS_LOADING,
} from "../actions/types";

const initialState = {
    customers: [],
    getAllCustomers: false,
    getCustomerDetail: false,
    getResponDataCustomer: false,
    errorResponDataCustomer: false,
    errorCustomerDetail: false,
    loading: false,
    errorCustomersList: false
}

const customers = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMERS:
            localStorage.setItem('allCustomers', JSON.stringify(action.payload.data));
            return {
                ...state,
                getAllCustomers: action.payload.data,
                loading: false
            };
        case GET_CUSTOMER_DETAILS:
            return {
                ...state,
                getCustomerDetail: action.payload.data,
                errorCustomerDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(customer => customer._id !== action.payload),
                loading: false
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                getResponDataCustomer: action.payload.data,
                errorResponDataCustomer: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                getResponDataCustomer: action.payload.data,
                errorResponDataCustomer: action.payload.errorMessage,
                loading: false
            }
        case CUSTOMERS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default customers