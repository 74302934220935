import React, { Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect} from 'react-redux';
import { FormGroup, Col, Label, Input, Row, Button } from "reactstrap";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment-localizer'
import  { Link, Redirect } from 'react-router-dom';
import UserValidation from "../validation/UserValidation";


momentLocalizer(moment);

const mapStateToProps = (state) => {
    return {
        initialValues: {
            firstname: state.users.getUserDetail.firstname,
            lastname: state.users.getUserDetail.lastname,
            email: state.users.getUserDetail.email,
            phone: state.users.getUserDetail.phone,
            street: state.users.getUserDetail.street,
            city: state.users.getUserDetail.city,
            state: state.users.getUserDetail.state,
            zip: state.users.getUserDetail.zip,
            role: state.users.getUserDetail.role === 'admin' ? 'admin' : 'commercial' || 'commercial',
        },
        getAllUsers: state?.users?.getAllUsers ? state?.users?.getAllUsers : JSON.parse(localStorage?.getItem('allUsers')),
        getAllCustomers: state?.customers?.getAllCustomers ? state?.customers?.getAllCustomers : JSON.parse(localStorage?.getItem('allCustomers')),
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getUserToken: localStorage.getItem('token'),
        getUserDetail: state.users.getUserDetail,
        getResponDataUser: state.users.getResponDataUser,
        errorResponDataUser: state.users.errorResponDataUser,
    };
};

const renderFieldInput = ({
                              input,
                              type,
                              placeholder,
                              label,
                              disabled,
                              readOnly,
                              meta: { touched, error, warning },
                          }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            />
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);

const renderFieldSelectRole = ({
                                   input,
                                   type,
                                   placeholder,
                                   label,
                                   disabled,
                                   readOnly,
                                   meta: { touched, error, warning },
                               }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            >
                <option  value='admin'>Admin</option>
                <option  value='commercial'>Commercial</option>
            </Input>
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);


class UserFormComponent extends Component {

    render()
    {
        if(!this.props.getUserConnected || !this.props.getUserToken){
            return <Link to="/"/>
        }
        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormGroup row>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="lastname"
                                label="Nom : "
                                placeholder="Nom du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="firstname"
                                label="Prenom : "
                                placeholder="Prenom du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="email"
                                component={renderFieldInput}
                                name="email"
                                label="Email : "
                                placeholder="Email du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="password"
                                component={renderFieldInput}
                                name="password"
                                label="Mot de passe : "
                                placeholder="Mot de passe"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="number"
                                component={renderFieldInput}
                                name="phone"
                                label="Téléphone : "
                                placeholder="Téléphone du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="street"
                                label="Rue : "
                                placeholder="Rue du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="zip"
                                label="Code postal : "
                                placeholder="Code postal du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="city"
                                label="Ville : "
                                placeholder="Ville du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="state"
                                label="Pays : "
                                placeholder="Pays du commercial"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                component={renderFieldSelectRole}
                                label="Role :"
                                type="select"
                                name="role"/>
                        </FormGroup>
                    </Col>
                </FormGroup>

                <FormGroup row className="my-0">
                    <Col md="12">
                        <FormGroup>
                            <Button
                                color="primary"
                                type="submit"
                                disabled={this.props.submitting}
                            >
                                Valider
                            </Button>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </form>
        );
    }
}

  UserFormComponent = reduxForm({
    form: 'formCreateUser',
    validate: UserValidation,
    enableReinitialize: true,
})(UserFormComponent);

export default connect(mapStateToProps, null)(UserFormComponent)