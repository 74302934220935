import React, {Component} from 'react';
import {
    BrowserRouter,
    Route,
} from "react-router-dom";
import AppNavbar from "./components/AppNavbar";
import {Container} from "reactstrap";
import store from './store';
import {loadUser} from "./actions/authActions";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './App.scss';
import PropTypes from "prop-types";
import TaskContainer from "./containers/Tasks/TaskContainer";
import CreateTaskContainer from "./containers/Tasks/CreateTaskContainer";
import DetailTaskContainer from "./containers/Tasks/DetailTaskContainer";
import EditTaskContainer from "./containers/Tasks/EditTaskContainer";
import CustomerContainer from "./containers/Customers/CustomerContainer";
import CreateCustomerContainer from "./containers/Customers/CreateCustomerContainer";
import EditCustomerContainer from "./containers/Customers/EditCustomerContainer";
import DetailCustomerContainer from "./containers/Customers/DetailCustomerContainer";
import UserContainer from "./containers/Users/UserContainer";
import CreateUserContainer from "./containers/Users/CreateUserContainer";
import DetailUserContainer from "./containers/Users/DetailUserContainer";
import EditUserContainer from "./containers/Users/EditUserContainer";
import CreateDocumentContainer from "./containers/Documents/CreateDocumentContainer";
import MetaTags from 'react-meta-tags';


class App extends Component {


    static propTypes = {
        isAuthenticated: PropTypes.bool
    }

    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <MetaTags>
                        <meta name="google" content="notranslate"/>
                    </MetaTags>
                    <AppNavbar/>
                    <Container>
                            <Route path="/" exact component={TaskContainer}>
                            </Route>
                            <Route path="/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/customerList" exact component={CustomerContainer}>
                            </Route>
                            <Route path="/createCustomer" exact component={CreateCustomerContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id" exact component={DetailCustomerContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/createDocument/:id"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/editCustomer/:id" exact component={EditCustomerContainer}>
                            </Route>
                            <Route path="/userList" exact component={UserContainer}>
                            </Route>
                            <Route path="/createUser" exact component={CreateUserContainer}>
                            </Route>
                            <Route path="/detailUser/:id" exact component={DetailUserContainer}>
                            </Route>
                            <Route path="/detailUser/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailUser/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailUser/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/editUser/:id" exact component={EditUserContainer}>
                            </Route>
                    </Container>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
